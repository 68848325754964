<template>
  <div id="temp_container">
    <MainHeader />
    <IntroView :background_images="['intro1.png', 'intro2.png', 'intro3.png', 'intro4.png', 'intro5.png']"/>
    <div class="orig_container">
      <BasicInformation />
    </div>
    <SectionHead
        :images="['intro2_1.png', 'intro2_2.jpg', 'intro2_3.jpg']"
        :background_color="'#0D0D0E'"
        :heading="$t('holiday_houses')"
        :subheading="$t('holiday_houses_subhead')"
        :paragraph_1="$t('holiday_houses_head_paragraph_1')"
        :paragraph_2="$t('holiday_houses_head_paragraph_2')"
        :main_id="'holiday_houses'"/>
    <div class="orig_container">
      <HolidayHousesBody />
    </div>
    <HolidayHousesSwitcher />
    <SectionHead
        :images="['intro3_1.jpg', 'intro3_2.jpg', 'intro3_4.jpg', 'intro3_5.png']"
        :background_color="'#0D0D0E'"
        :heading="$t('sustainability')"
        :subheading="$t('sustainability_subhead')"
        :paragraph_1="$t('sustainability_head_paragraph_1')"
        :paragraph_2="$t('sustainability_head_paragraph_2')"
        :main_id="'sustainability'"/>
    <div class="orig_container">
      <SustainabilityBody />
    </div>
    <SectionHead
        :images="['intro2_3.png', 'intro4_2.jpg', 'intro4_3.jpg', 'intro4_4.jpg', 'intro4_5.jpg', 'intro4_6.jpg']"
        :background_color="'#CDA787'"
        :heading="$t('recreation')"
        :subheading="$t('recreation_subhead')"
        :paragraph_1="$t('recreation_head_paragraph_1')"
        :paragraph_2="$t('recreation_head_paragraph_2')"
        :main_id="'recreation'"/>
    <RecreationSwitcher />
    <RecreationSlider />
    <ContactUs />
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader';
import IntroView from '@/components/IntroView';
import BasicInformation from '@/components/BasicInformation';
import HolidayHousesBody from '@/components/HolidayHousesBody';
import HolidayHousesSwitcher from '@/components/HolidayHousesSwitcher';
import SustainabilityBody from '@/components/SustainabilityBody';
import RecreationSlider from '@/components/RecreationSlider';
import ContactUs from '@/components/ContactUs';
import MainFooter from '@/components/MainFooter';
import RecreationSwitcher from "@/components/RecreationSwitcher";
import SectionHead from "@/components/templates/SectionHead";

export default {
  name: 'App',
  components: {
    MainHeader,
    IntroView,
    BasicInformation,
    HolidayHousesBody,
    HolidayHousesSwitcher,
    SustainabilityBody,
    RecreationSlider,
    ContactUs,
    MainFooter,
    RecreationSwitcher,
    SectionHead,
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: 'maajo.com' })
    }
  },
  mounted() {
    /*this.$gtag.event('test', {method: 'Google'})*/

/*    this.$gtag.screenview({
      app_name: 'Maajo',
      screen_name: 'Home',
    })*/
  }
}
</script>

<style>

.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 1s;
}

html {
  scroll-behavior: smooth;
}

#temp_container {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white_color);
}
.orig_container {
  width: calc(100vw - 180px);
  margin: auto;
}
.section::before {
  content: " ";
  display: block;
  height: 80px;
  margin: -80px 0 0 0;
}
:target {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
@media (max-width: 600px) {
  .section::before {
    height: 64px;
    margin: -64px 0 0 0;
  }
  :target {
    top: -64px;
  }
}
@media (min-width: 1680px) {
  .orig_container {
    width: 1440px;
    margin: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1680px) {
  .orig_container {
    width: calc(100vw - 160px);
    margin: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .orig_container {
    width: calc(100vw - 100px);
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .orig_container {
    width: calc(100vw - 100px);
    margin: auto;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .orig_container {
    width: calc(100vw - 60px);
    margin: auto;
  }
}
@media (max-width: 576px) {
  .orig_container {
    width: calc(100vw - 30px);
    margin: auto;
  }
}

</style>
