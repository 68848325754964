import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import i18n from './i18n'
import VueGtag from "vue-gtag"

const app = createApp(App);

app.use(i18n);

app.use(VueGtag, {
    appName: 'Maajo',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: "UA-214178687-1",
        params: {
            send_page_view: true,
        }
    },
});

app.mount('#app');
