export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A compléter par des traductions franchisées"])},
  "basic_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a place for you to take a break and escape from all the commotion."])},
  "basic_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO has been a family passion project and a quest to create the perfect holiday house. A place to experience the rejuvenating sense of calm that brings us inner strength and confidence. The beautiful, relaxing, and minimalist Nordic style interior combined with the soothing ambiance of the Baltic seaside and the charming meadows allow our guests to encounter true peace. The large glass surfaces allow you to be immersed and reconnect with the beauties of countryside nature. Without leaving the comforts of city life."])},
  "basic_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stylish black minimalist kitchen comes fully equipped with all the appliances you will need: stove, oven, microwave, fridge, dishwasher, and even a washing machine. We have prepared everything to guarantee maximum convenience during your stay. The sturdy dining table can accommodate 5 people. Enjoy a strong cup of the finest Illy coffee or a smooth, calming Latvian herbal tea here!"])},
  "basic_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimalist interior won’t distract you from the wonders that await outside: majestic pine treas, graceful junipers and mysterious heathers bordering the natural seaside meadow."])},
  "holiday_houses_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holiday houses"])},
  "holiday_houses_decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood, linen, and glass – these materials create a pleasant blend of rustic and modern interior."])},
  "holiday_houses_extra_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO houses are equipped with the most efficient recuperation and air conditioning systems that will keep you cool in the summer and warm in the winter."])},
  "holiday_houses_extra_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And there is enough space for the whole family or a group of 5 friends! There is a spacious double bed, a comfortable double roll-up mattress, and a nice single roll-up."])},
  "bathroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bathroom"])},
  "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terrace"])},
  "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedroom"])},
  "bedroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A full night's sleep is essential for so many reasons. That's why each holiday house has a separate bedroom with an open space corridor leading to the rest of the house."])},
  "bedroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A spacious double bed, a comfortable double roll-up mattress, and a nice single roll-up can accommodate up to 5 guests. Custom-made linen bed sheets ensure the best sleeping conditions year-round: warm in the winter, cool in the summer. The soft and natural touch of this Nordic palette linen, the fine down pillows and blankets – these are the key ingredients for the best possible dreams at night."])},
  "bedroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The large glass facade offers a uniquely immersive experience. It's almost as if you would wake up in the middle of the seaside meadow! Feeling the sun's warm rays on your face peeking through the cozy linen curtains... Feeling productive? There is a handy table for reading, remote working or studying, and a Wi-Fi network."])},
  "holiday_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stylish black minimalist kitchen comes fully equipped with all the appliances you will need: stove, oven, microwave, fridge, dishwasher, and even a washing machine. We have prepared everything to guarantee maximum convenience during your stay. The sturdy dining table can accommodate 5 people. Enjoy a strong cup of the finest Illy coffee or a smooth, calming Latvian herbal tea here!"])},
  "holiday_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unwind on the comfortable linen sofa while enjoying a gorgeous view or warm up by lighting the fireplace."])},
  "sustainability_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustainability"])},
  "sustainability_decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in sustainability - the shared responsibility towards our planet."])},
  "sustainability_extra_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in sustainability - the shared responsibility towards our planet. That's why our houses are built of ethically-sourced, cross-laminated timber. "])},
  "sustainability_extra_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We chose the charred wood technique for the facade because that is the most natural way of weatherproofing the house. And it brings out the hidden beauty of the wooden texture."])},
  "recreation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreation"])},
  "recreation_decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is so much to see, do and enjoy in this picturesque corner of Latvia."])},
  "recreation_extra_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can hop on a bike and travel to nearby beaches and villages - full of alluring stories and fascinating craftsmanship."])},
  "recreation_extra_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or have a nice picnic on a remote beach on your way to Upesgrīva - with gentle waves whispering their secrets just for you."])},
  "kolka_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolka beach"])},
  "upegriva_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upegriva beach"])},
  "valgalciems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valgalciems"])},
  "recreation_main_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wild beach of Valgalciems"])},
  "recreation_sub_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a lot of people know about this scenic place. But plenty of birds do! Valgalciems is an exceptional place for birdwatching."])},
  "recreation_p1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sometimes see hundreds of swans and many other species - from the good old silver gulls and black cormorants to the occasional long-tailed ducks and even the rare white-tailed eagle! If there is swimming on your mind, we recommend taking a short walk (1-3 km) towards Upesgrīva. "])},
  "recreation_p2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a striking view there - great for outdoor picnics! And the Upesgrīva bay is less prone to having all kinds of water grasses showing up during windless periods."])},
  "biking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biking"])},
  "sup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sup"])},
  "hot_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hot tub"])},
  "reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reading"])},
  "sustainability_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can become a self-sufficient person inside a self-sufficient house. No one to interrupt your flow. Respect the local environment - and it will reward you. With peace of mind. The happiness of heart. The joys of life. Blissful tranquility."])},
  "sustainability_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to cleaning and service - only nature-friendly materials are used. We wash all the towels and bed linen solely with ecological detergents. The soap and the shower gel are also Eco-labeled. And there is a water closet connected to a biodegradable waste system. Because every little bit helps if we're to save the planet. "])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address:"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile:"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail:"])},
  "maajo_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO BOUTIQUE HOTEL, Beķeri, Valgalciems, Talsu novads, Latvia"])},
  "maajo_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+371 +371 25447117"])},
  "maajo_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infomaajo.com"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow:"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facebook"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instagram"])},
  "contact_us_additional_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*We kindly ask you to respect the local people and have a peaceful, quiet stay."])},
  "contact_us_additional_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, help keep our neighbors happy and stay in the MAAJO territory and only use the provided official paths to the sea. Stay off the neighboring land. It contains state-protected biotopes and rare plant species that should not be endangered by human activity. Thank you for understanding!"])}
}