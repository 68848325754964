<template>
  <div class="header">
    <Transition name="menu">
      <div class="home_menu" v-if="homeIsHidden" v-click-outside="clickMenuOutside">
        <a class="caps" href="#home" @click="menuClick">{{ $t('home') }}</a>
        <a class="caps" href="#holiday_houses" @click="menuClick">{{ $t('holiday_houses') }}</a>
        <a class="caps" href="#sustainability" @click="menuClick">{{ $t('sustainability') }}</a>
        <a class="caps" href="#recreation" @click="menuClick">{{ $t('recreation') }}</a>
        <a class="caps" href="#contact_us" @click="menuClick">{{ $t('contact_us') }}</a>
        <div class="image_box"></div>
        <img class="x_icon" src="@/assets/images/closer.png" @click="menuClick">
      </div>
    </Transition>
    <img class="menu_icon" src="../assets/icons/Vector.png" alt="Menu icon" @click="menuClick">
    <div class="language_button" @click="langIsHidden = !langIsHidden" v-click-outside="clickLangOutside">{{ $i18n.locale }}</div>
    <a href="https://hotels.cloudbeds.com/reservation/Mmiucq#promo=Discount%205%25" target="_blank">
      <div class="booking_button">
        <div class="button_text">BOOOK A ROOM</div>
        <img class="arrow_icon" src="@/assets/icons/Arrow1.png" alt="arrow point right">
      </div>
    </a>
    <div class="logo">
      <a id="logo_link" href="#home" >
        <p class="logo_maajo">MAAJO</p>
        <p class="logo_boutique_hotel">BOUTIQUE HOTEL</p>
      </a>
    </div>
    <Transition name="lang">
      <div class="lang_select" v-if="langIsHidden">
        <div v-for="(locale, i) in locales" :key="`locale-${i}`"  class="lang_options">
          <p style="text-transform: uppercase;" @click="selectNewLocale(locale)" :value="locale">
            <span style="pointer-events: none">
              {{ locale }}
            </span>
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {

  name: 'MainHeader',
  methods: {
    clickMenuOutside () {
      if (this.homeIsHidden === true) {
        this.homeIsHidden = !this.homeIsHidden
      }
    },
    clickLangOutside () {
      if (this.langIsHidden === true) {
        this.langIsHidden = !this.langIsHidden
      }
    },
    menuClick() {
      this.homeIsHidden = !this.homeIsHidden
    },
    selectNewLocale(locale) {
      // let newLocale = event.originalTarget.firstChild.textContent
      let newLocale = locale;
      let languages = ["en","lv"/*,"lt","fr"*/]
      let index = languages.findIndex((element) => element === newLocale)
      languages.splice(index, 1)

      this.locales = languages
      this.$root.$i18n.locale = newLocale
      this.langIsHidden = false
    }
  },
  data() {
    return {
      langIsHidden: false,
      homeIsHidden: false,
      locales: ["lv"/*,"lt","fr"*/],
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.menu-enter-active, .menu-leave-active {
  transition: opacity 0.5s ease;
}
.menu-enter-from,
.menu-leave-to {
  opacity: 0;
}
.lang-enter-active {
  transition: all 0.2s linear;
}
.lang-leave-active {
  transition: all 0.2s linear;
}

.lang-enter-from,
.lang-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

  .header {
    height: 80px;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--header_color);
    z-index: 10;
  }
  .menu_icon {
    float: left;
    height: 20px;
    width: 34px;
    position: relative;
    left: 40px;
    top: 30px;
  }
  .menu_icon:hover {
    cursor: pointer;
  }
  .language_button {
    display: inline-block;
    float: left;
    position: relative;
    left: 100px;
    top: 27px;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    color: #0D0D0E;
    text-transform: uppercase;
    padding: 5px;
    border: 1px solid  	#F5F5F5;
    border-radius: 5px;
  }
  .language_button:hover {
    cursor: pointer;
  }
  .language_button:active {
    background-color: #E8E8E8;
  }
  .logo {
    text-align: center;
    margin-left: calc((100vw - 200px) / 2);
    height: 80px;
    width: 200px;
  }
  .logo_maajo {
    color: #0D0D0E;
    font-size: 28px;
    font-weight: 500;
    position: relative;
    top: 15px;
  }
  .logo_boutique_hotel {
    color: #0D0D0E;
    font-size: 9px;
    position: relative;
    top: 15px;
  }
  #logo_link {
    text-decoration: none;
  }
  .booking_button {
    height: 51px;
    width: 188px;
    background-color: #0D0D0E;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    position: relative;
    right: 14px;
    top: 14px;
  }
  .button_text {
    font-size: 12px;
    color: #FFFFFF;
  }
  .arrow_icon {
    width: 21px;
    height: 12px;
    position: relative;
    left: 10px;
  }
  .lang_select {
    position: absolute;
    height: 42px;
    width: 51px;
    background-color: var(--header_color);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    left: 124px;
    top: 53px;
  }
  .lang_options {
    width: 51px;
    height: 42px;
    font-size: 14px;
    font-weight: 300;
    line-height: 42px;
    color: #8A8A8A;
    text-align: center;
  }
  .lang_options:hover {
    cursor: pointer;
    color: #0D0D0E;
  }
  .home_menu {
    z-index: 20;
    background-color: var(--back_color);
    height: 100vh;
    width: 550px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .x_icon {
    width: 3vh;
    min-width: 18px;
    max-width: 28px;
    position: absolute;
    top: 30px;
    left: 40px;
  }
  .x_icon:hover {
    cursor: pointer;
  }
  .home_menu > div {
    z-index: 21;
    position: absolute;
    top: 15vh;
    left: 200px;
    width: 295px;
    height: 300px;
    background-image: url("@/assets/images/intro3.png");
    box-shadow: inset 0 0 0 50vw rgba(13, 13, 14, 0.18);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  a.caps {
    font-size: 50px;
    position: absolute;
    top: 0;
    left: 55px;
    font-weight: var(--normal);
    color: var(--white_color);
    text-transform: uppercase;
    z-index: 22;
    text-decoration: none;
    opacity: 0.5;
  }
  .caps:hover {
    text-decoration: underline;
    opacity: 1;
  }
  .caps:nth-child(1) {
    margin-top: 23.5vh;
  }
  .caps:nth-child(2) {
    margin-top: calc(23.5vh + 73px);
  }
  .caps:nth-child(3) {
    margin-top: calc(23.5vh + 146px);
  }
  .caps:nth-child(4) {
    margin-top: calc(23.5vh + 219px);
  }
  .caps:nth-child(5) {
    margin-top: calc(23.5vh + 292px);
  }
  .hidden {
    display: none;
  }
  a.anchor {
    display: block;
    position: relative;
    top: -80px;
    /*visibility: hidden;*/
  }
  @media (max-width: 700px) {
    .language_button {
      left: 14vw;
    }
    .booking_button {
      width: 140px;
      border-radius: 30px;
    }
    .arrow_icon {
      width: 18px;
      left: 5px;
    }
    .lang_select {
      left: calc(15vw + 19px);
    }
  }
  @media (max-width: 600px) {
    .header {
      height: 64px;
    }
    .logo_maajo {
      top: 23px;
    }
    .logo_boutique_hotel {
      display: none;
    }
    .menu_icon {
      height: 14px;
      width: 20px;
      left: 20px;
      top: 25px;
    }
    .language_button {
      left: 11vw;
      top: 19px;
    }
    .lang_select {
      left: calc(12vw + 7px);
      top: 46px;
    }
    .logo_maajo {
      color: #0D0D0E;
      font-size: 24px;
      top: 18px;
    }
    .booking_button {
      right: 7px;
      top: 7px;
    }
  }
  @media (max-width: 430px) {
    .logo {
      display: none;
    }
    .language_button {
      left: 15vw;
    }
    .lang_select {
      left: calc(15vw + 11px);
    }
  }
  @media (max-height: 500px) {
    .home_menu > div {
      top: 15vh;
      left: 200px;
      width: 195px;
      height: 200px;
    }
    a.caps {
      font-size: 32px;
    }
    .caps:nth-child(1) {
      margin-top: 13.5vh;
    }
    .caps:nth-child(2) {
      margin-top: calc(13.5vh + 58px);
    }
    .caps:nth-child(3) {
      margin-top: calc(13.5vh + 116px);
    }
    .caps:nth-child(4) {
      margin-top: calc(13.5vh + 174px);
    }
    .caps:nth-child(5) {
      margin-top: calc(13.5vh + 232px);
    }
  }
  @media (max-width: 600px) {
    .x_icon {
      top: 20px;
      left: 15px;
    }
  }
  @media (max-width: 770px) {
    .home_menu {
      width: 100vw;
    }
    .home_menu > div {
      left: 250px;
    }
  }
  @media (max-width: 550px) {
    .home_menu > div {
      left: 200px;
    }
    a.caps {
      font-size: 30px;
    }
  }
  @media (max-width: 450px) {
    .home_menu > div {
      top: 20vh;
      left: 150px;
      width: 195px;
      height: 200px;
    }
    a.caps {
      font-size: 25px;
      left: 20px;
    }
  }
  @media (max-width: 350px) {
    .home_menu > div {
      top: 20vh;
      left: 100px;
      width: 195px;
      height: 200px;
    }
    a.caps {
      font-size: 25px;
      left: 15px;
    }
  }
</style>