<template>
  <div class="outer_container">
    <span id="contact_us"></span>
    <div class="main_container">
      <div class="container_1">
        <div class="contact_us_container">
          <hr class="hr_rule">
          <p class="caps">{{ $t('contact_us') }}</p>
        </div>
      </div>
      <div class="container_2">
        <div class="contact_info_container">
          <p class="subtitle_2">{{ $t('address') }}</p>
          <p class="paragraph_two mt_16">{{ $t('maajo_address') }}</p>
          <p class="subtitle_2 mt_32">{{ $t('mobile') }}</p>
          <a class="paragraph_two mt_16" :href="`tel:${$t('maajo_phone_number')}`">{{ $t('maajo_phone_number') }}</a>
          <p class="subtitle_2 mt_32">{{ $t('email') }}</p>
          <a class="paragraph_two mt_16" href="mailto:info@maajo.com">info@maajo.com</a>
        </div>
      </div>
      <div class="container_3">
        <div class="follow_container">
          <p class="subtitle_2">{{ $t('follow') }}</p>
          <a href="https://www.facebook.com/maajoboutiquehotel/" target="_blank" class="contact_us_links mt_22"><img class="contact_us_icons" src="@/assets/icons/facebook_logo.png">{{ $t('facebook') }}</a>
          <br>
          <a href="https://www.instagram.com/maajo.boutique.hotel/" target="_blank" class="contact_us_links mt_21"><img class="contact_us_icons" src="@/assets/icons/instagram_logo.png">{{ $t('instagram') }}</a>
        </div>
      </div>
      <div class="container_4"></div>
      <hr class="hidden">
      <div class="container_5">
        <div class="informational_container">
          <p class="paragraph_two">
            {{ $t('contact_us_additional_1') }}
          </p>
          <br>
          <p class="paragraph_two">
            {{ $t('contact_us_additional_2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*Main footer part*/
.main_container {
  height: 50vh;
  max-height: 50vh;
  width: 100%;
  background-color: #0D0D0E;
  overflow: hidden;
  display: flex;
  padding-top: 10vh;
}
.hr_rule {
  width: 42px;
  height: 1px;
  margin-top: 13px;
  color: var(--white_color);
  margin-right: 20px;
}
p.caps {
  width: 100px;
}
.contact_us, .text_size_10, .text_size_12 {
  color: #FFFFFF;
  line-height: 16px;
}
img.contact_us_icons {
  width: 16px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
a.paragraph_two {
  color: var(--white_color);
  font-size: var(--p_2_pc_size);
  text-underline-position: under;
  display: inline-block;
}

/* One liners */
.mt_16 {
  margin-top: 16px;
}
.mt_32 {
  margin-top: 32px;
}
.mt_22 {
  margin-top: 22px;
}
.mt_21 {
  margin-top: 21px;
}

/*container*/
.container_1 {
  height: 100%;
  width: 28vw;
}
.container_2 {
  height: 100%;
  width: 19vw;
}
.container_3 {
  height: 100%;
  width: 18vw;
}
.container_4 {
  height: 229px;
  width: 0vw;
  border-left: 1px solid #FFFFFF;
  opacity: 0.5;
  border-collapse: collapse;
  /*margin-top: 120px;*/
}
.container_5 {
  height: 100%;
  width: calc(35vw - 1px);
}
.contact_us_container {
  width: 155px;
  height: 14px;
  display: flex;
  position: relative;
  /*top: 120px;*/
  left: 82px;
}
.contact_info_container {
  width: 196px;
  /*margin-top: 120px;*/
}
.follow_container {
  /*margin-top: 120px;*/
}
.informational_container {
  height: 176px;
  width: 240px;
  /*margin-top: 140px;*/
  margin-top: 20px;
  margin-left: 79px;
}
a.contact_us_links {
  color: var(--white_color);
  text-transform: uppercase;
  font-size: var(--caps_s_pc_size);
  display: inline-block;
  text-underline-position: under;
  width: 100px;
}
.hidden {
  display: none;
}
@media (min-width: 1680px) {
  .outer_container {
    width: 100%;
    background-color: var(--back_color);
  }
  .main_container {
    width: 1440px;
    margin: auto;
    height: 495px;
  }
}
@media (max-width: 1050px) {
  .informational_container {
    margin-left: 5vw;
  }
  .container_3 {
    width: 12vw;
  }
  .follow_container {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .outer_container {
    width: 100vw;
    /*height: 110vh;*/
    max-height: 190vh;
    background-color: var(--back_color);
    overflow: hidden;
  }
  .main_container {
    width: calc(100vw - 100px);
    height: 100%;
    max-height: 190vh;
    margin: auto;
    display: block;
    padding-top: 4vh;
  }
  .container_1 {
    height: 20px;
  }
  .contact_us_container {
    left: 0;
  }
  .container_2 {
    margin-top: 6vh;
    width: 100%;
    height: 200px;
  }
  .contact_info_container {
    width: 100%;
  }
  .container_3 {
    height: 100px;
    margin-top: 6vh;
  }
  .container_4 {
    display: none;
  }
  .hidden {
    display: block;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .container_5 {
    width: 100%;
    height: 85px;
    margin-bottom: 5vh;
  }
  .informational_container {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .main_container {
    width: calc(100vw - 60px);
  }
}
@media (max-width: 576px) {
  .main_container {
    width: calc(100vw - 30px);
  }
}
@media (max-width: 700px) {
  .container_5 {
    height: 101px;
  }
}
@media (max-width: 550px) {
  .container_5 {
    height: 155px;
  }
}
@media (max-width: 400px) {
  .container_5 {
    height: 170px;
  }
}
@media (max-width: 320px) {
  .container_5 {
    height: 180px;
  }
}
@media (max-width: 270px) {
  .container_5 {
    height: 220px;
  }
}
</style>
